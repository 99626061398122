<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-form ref="form" @submit.prevent="validate">
          <v-card-title>Tambah File</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Nama"
                  placeholder="Nama"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="data.name"
                  required
                  :rules="[v => !!v || 'Nama harus diisi!']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="URL"
                  placeholder="URL"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="data.seo_url"
                  required
                  :rules="[v => !!v || 'URL harus diisi!']"
                ></v-text-field>
                <a href="javascript:void(0)" @click="seoURL">Generate URL</a>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn
                  outlined
                  color="primary"
                  depressed
                  small
                  @click="openFile('attach_thumbnail')"
                  id="button-upload-thumbnail"
                  >Upload Thumbnail</v-btn
                >
                <input
                  type="file"
                  style="display: none"
                  id="attach_thumbnail"
                  @change="changeFile($event, 'thumbnail', 'button-upload-thumbnail', 2048, 'file_name_thumbnail')"
                  accept="image/*"
                />
                <input type="hidden" id="thumbnail" />
                <input type="hidden" id="file_name_thumbnail" />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="data.status"
                  :items="statuses"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  label="Status"
                  placeholder="Status"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mb-5">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Nama File</th>
                        <th>Versi</th>
                        <th>File</th>
                        <th>Tanggal Update</th>
                        <th>Aksi?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(detail, index) in data.details" :key="index">
                        <td>
                          <v-text-field
                            label="Nama File"
                            placeholder="Nama File"
                            outlined
                            dense
                            hide-details="auto"
                            v-model="detail.name"
                            required
                            :rules="[v => !!v || 'Nama harus diisi!']"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            label="Versi"
                            placeholder="Versi"
                            outlined
                            dense
                            hide-details="auto"
                            v-model="detail.version"
                            required
                            :rules="[v => !!v || 'Versi harus diisi!']"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-btn
                            color="primary"
                            outlined
                            depressed
                            small
                            :id="`button-upload-file-${detail.id}`"
                            @click="openFile(`attach_file_${detail.id}`)"
                          >
                            Upload
                          </v-btn>
                          <input
                            type="file"
                            :id="`attach_file_${detail.id}`"
                            style="display: none"
                            @change="
                              changeFile(
                                $event,
                                `file_${detail.id}`,
                                `button-upload-file-${detail.id}`,
                                0,
                                `file_name_file_${detail.id}`,
                              )
                            "
                          />
                          <input type="hidden" :id="`file_${detail.id}`" />
                          <input type="hidden" :id="`file_name_file_${detail.id}`" />
                        </td>
                        <td>
                          <v-menu
                            :ref="`datepicker-menu-${index}`"
                            v-model="detail.date_picker"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                label="Tanggal Update"
                                placeholder="Tanggal Update"
                                outlined
                                hide-details="auto"
                                dense
                                v-model="detail.updated_date_file"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="detail.updated_date_file" scrollable no-title> </v-date-picker>
                          </v-menu>
                        </td>
                        <td>
                          <v-btn color="error" depressed small @click="deleteDetail(detail)">Hapus</v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="5">
                          <v-btn color="info" small @click="addDetail">Tambah</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" type="submit" :loading="loadingButton">Simpan</v-btn>
            <v-btn color="primary" outlined class="ml-3" @click="$router.go(-1)">Batal</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>

    <v-snackbar v-model="alert.status" :timeout="timeout" :color="alert.success ? 'success' : 'error'" right bottom>
      {{ alert.message }}
    </v-snackbar>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical, mdiRefresh, mdiFileOutline, mdiClose } from '@mdi/js'
import axios from 'axios'
import { shortlessDateTime, seoURL } from '@/utils'

export default {
  setup() {
    return {
      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiRefresh,
        mdiFileOutline,
        mdiClose,
      },
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('user'))
    },
  },
  data() {
    return {
      alert: {
        status: false,
        success: false,
        message: '',
      },
      timeout: 1200,
      loadingButton: false,
      data: {
        name: '',
        seo_url: '',
        image: '',
        status: 1,
        filename: '',
        details: [],
      },
      statuses: [
        { name: 'Aktif', value: 1 },
        { name: 'Tidak Aktif', value: 0 },
      ],
    }
  },
  mounted() {},
  methods: {
    validate() {
      this.changeFileVariable()
      if (this.$refs.form.validate()) {
        // check image
        if (this.data.image === '') {
          this.alert = {
            success: false,
            status: true,
            message: 'Thumbnail harus diisi!',
          }
          return
        }

        // check detail
        if (this.data.details.length === 0) {
          this.alert = {
            success: false,
            status: true,
            message: 'Detail harus diisi!',
          }
          return
        }

        for (const detail of this.data.details) {
          if (detail.file === '') {
            this.alert = {
              success: false,
              status: true,
              message: 'File harus diisi!',
            }
            return
          }
        }

        this.submit()
      }
    },
    submit() {
      this.loadingButton = true
      const token = localStorage.getItem('token')
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}files`, this.data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          this.loadingButton = false
          if (res.status == 200) {
            const data = res.data
            this.alert = {
              status: true,
              success: data.success == 1,
              message: data.message
            }
            if (data.success) {
              this.$router.go(-1)
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText
            }
          }
        })
        .catch(err => {
          this.loadingButton = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    seoURL() {
      this.data.seo_url = seoURL(this.data.name)
    },
    openFile(id) {
      document.getElementById(id).click()
    },
    changeFile(event, id, buttonID, maxSize = 0, fileNameElementID) {
      const selectedElement = document.getElementById(id)
      const fileNameElement = document.getElementById(fileNameElementID)
      const button = document.getElementById(buttonID)
      const file = event.target.files[0]
      if (file) {
        const fsize = file.size
        const size = Math.round(fsize / 1024)

        if (maxSize > 0 && size > maxSize) {
          this.alert = {
            success: false,
            status: true,
            message: 'File terlalu besar!',
          }
          return
        }

        const reader = new FileReader()
        reader.onloadend = () => {
          const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')
          selectedElement.value = base64String
        }
        reader.readAsDataURL(file)
        button.innerHTML = file.name.length > 15 ? file.name.slice(0, 13) + '...' : file.name
        fileNameElement.value = file.name
      }
    },
    addDetail() {
      const length = this.data.details.length
      const detail = {
        id: length,
        name: '',
        version: '',
        file: '',
        updated_date_file: '',
        date_picker: false,
        filename: '',
      }
      this.data.details.push(detail)
    },
    deleteDetail(detail) {
      const filtered = this.data.details.filter(item => {
        return item.id !== detail.id
      })
      this.data.details = filtered
    },
    changeFileVariable() {
      const thumbnail = document.getElementById('thumbnail')
      const filename = document.getElementById('file_name_thumbnail')
      this.data.image = thumbnail.value
      this.data.filename = filename.value
      this.data.details.map(detail => {
        const file_detail_element = document.getElementById(`file_${detail.id}`)
        const filename_detail = document.getElementById(`file_name_file_${detail.id}`)
        detail.file = file_detail_element.value
        detail.filename = filename_detail.value
        return detail
      })
    },
  },
}
</script>

<style>
</style>